import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-time-attendance',
  templateUrl: './time-attendance.component.html',
  styleUrls: ['./time-attendance.component.scss']
})
export class TimeAttendanceComponent implements OnInit {
 
 _global = GlobalService
 obj:any
 userLocations:any
 userRoles:any
 isCheckInUser:boolean= true;
 isCheckOutReason:FormControl = new FormControl(false) ;
 checkOutReason;


 reasonList =[
  {id:1,value:'Check Out'},
  {id:2,value:'Break'},
  {id:3,value:'Other'},
]
  isAllowed: any = false;
  isLoadingLocation: boolean = false;

 constructor(
   public _userService:UserService,
   public _abd:AppBaseDataService,
   private _connMessageService: ConnMessageService,
 ){ }

  ngOnInit(): void {
    this.userLocations = this.getUserLocations(this._global?.userInfo?.userLocations,this._abd.locations)
    this.userRoles = this.getUserRoles(this._global?.userInfo?.role,this._abd.roles)
    this.isLoadingLocation = true
    setTimeout(() => {
      this.isLoadingLocation = false
    this.locationChanged({value:this._userService.clockInOutLocation})  
  }, 1000);
}

  clockIn(){
    this.obj ={
        UserId:        this._global?.userInfo?.userID,
        EmployeeID:    this._global?.userInfo?.userID,
        EmployeeRole:  this._userService?.clockInOutRole?.name ,
        EmployeeEmail: this._global?.userInfo.email,
        EmployeeName:  this._global?.userInfo.displayName,
        LocationName:  this._userService?.clockInOutLocation?.locationName,
        RegisterName:  this.isCheckOutReason?.value === true ? this._userService?.clockInOutRegister?.registerName || "Other":"Other",
        locationid:+this._userService?.clockInOutLocation?.id,
        Type:0,
        Method: 2,
        Reason:0,
       }
  //  if(!this.isAllowed && this._abd.locations.length > 0){
  this._userService.checkInUser(this.obj).subscribe((res)=>{
   localStorage.setItem('userCheckIn',JSON.stringify(this.obj))
   localStorage.setItem('userAttendance','check-in')
   this._userService.isCheckIn = false,
   this._userService.isCurrentCheckIn = true
   this.isCheckInUser = false;
   this._userService.isShowCheckIn = false
   })
      //  }else{
      //   this._connMessageService.showToastMessage('Check-In not allowed on this location', 'error')
      //  }
 
  }
  clockOut(){
    this._abd.showSpinner()
    this._userService.getTimeAttendance(this._global.userInfo.userID).subscribe((res)=>{
    if(res.data.type === 0){
      let obj ={
        UserId:        res.data?.userId,
        EmployeeID:    res.data?.userId,
        EmployeeRole:  res.data?.employeeRole,
        EmployeeEmail: res.data?.employeeEmail, 
        EmployeeName:  res.data?.employeeName,
        LocationName:  res.data?.locationName,
        RegisterName:  res.data?.registerName,
        locationid:    res.data?.locationId,
        Type:          1,
        Method:        res.data?.method,
        reason:        this.checkOutReason.id,
       }
 
    this._userService.checkOutUser(obj).subscribe((res)=>{
        localStorage.setItem('userAttendance','not-check-in'),
     this._userService.isCurrentCheckIn = false
     this._userService.isCheckIn = false
     this.isCheckInUser = false;  })

    }})
  }
  getUserLocations(array1?: string[], array2?: any[]): any[] {
    const roleIdSet = new Set(array1);
    return array2.filter(obj => roleIdSet.has(obj.locationId.toString()));
  }
  getUserRoles(array1?: string[], array2?: any[]): any[] {
    const roleIdSet = new Set(array1);
    return array2.filter(obj => roleIdSet.has(obj.roleId.toString()));
}
locationChanged(e){
  this.isLoadingLocation = true;
  this._abd.getTimeTrackingConfigurationWithFilter(e.value.locationId).subscribe((res)=>{
    this.isAllowed = res.data.active
     this.isLoadingLocation = false;
    },(error)=>{
      this.isLoadingLocation = false;
    })
}

dismiss(){
  this._userService.isCheckIn = false
}

}