import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

@Component({
  selector: 'app-signup-business-info',
  templateUrl: './signup-business-info.component.html',
  styleUrls: ['./signup-business-info.component.scss']
})
export class SignupBusinessInfoComponent implements OnInit {
  editform: FormGroup

  isButtonDisable:boolean = false;
//   usaStates = UsStates
//   showCityWait = false
//   selectedState: any = ''
  companyNameExist = false

  _globals = GlobalService
    @Output() backButtonClick = new EventEmitter();
    constructor(
        public _signup: SignupService,
        private _fb: FormBuilder,
        public _abd: AppBaseDataService,
        private _connMessageService: ConnMessageService,
        private _spinner: NgxSpinnerService,
    ) { }

  get f() {
    return this.editform.controls;
  }

  ngOnInit(): void {
    this.initForm()
  }

    initForm() {
        this.editform = this._fb.group({
            companyName: [this._signup.businessInfo.companyName, Validators.required],
            // email: [{ value: this._signup.businessInfo?.email, disabled: this._signup.isViewOnly }, [Validators.required, this.emailFormatValidator()]],
            // phone: [this._signup.businessInfo.phone, Validators.required],
            // address: [this._signup.businessInfo.address, Validators.required],
            // postalCode: [this._signup.businessInfo.zip, Validators.required],
            // city: [this._signup.businessInfo.city, Validators.required],
            // state: ''
        })
        // this.selectedState = this.usaStates.find(s => s.abbreviation == this._signup.businessInfo.state)
    }

//   dummy() {
    // _signup?.businessInfo.phone = $event.target.value
//   }

//   emailFormatValidator(): ValidatorFn {
//     return (control: AbstractControl): ValidationErrors | null => {
//       const value = control.value;
//       if (!value) return null;

//       const pvalid = value.match(this._globals.emailValidatorString)
//       return pvalid ? null : { emailStrength: true };
//     }
//   }

//   passwordStrengthValidator(): ValidatorFn {
//     return (control: AbstractControl): ValidationErrors | null => {
//       const value = control.value;
//       if (!value) return null;

//       const pvalid = value.match(this._globals.passwordValidatorString)
//       return pvalid ? null : { passwordStrength: true };
//     }
//   }

    saveFormDataToService() {
        let obj = this._signup.businessInfo
        obj.companyName = this.editform.get('companyName').value
        // obj.phone = this.editform.get('phone').value.replace(/\D/g, '')
        // obj.email = this.editform.get('email').value
        // obj.address = this.editform.get('address').value
        // obj.zip = this.editform.get('postalCode').value
        // obj.city = this.editform.get('city').value
    }

    savePersonalInformation(){

        // if (this._signup.userAccountPendingForPayment) {
        //     delete this._signup.personalInfo.password ;
        //     delete this._signup.personalInfo.confirmPassword;

        // }
        this._signup.personalInfo.confirmEmail = this._signup.personalInfo.email;

        this._signup.saveBusinessUserV2(this._signup.personalInfo).subscribe(res => {
            // this._spinner.hide()


            if (res.success) {
    
                this._signup.personalInfo.userID = res.data
                //this._signup.currentStep++;
               // this._signup.savedSteps++;
                sessionStorage.setItem('step-1', JSON.stringify(this._signup.personalInfo))
                this.saveBusinessInformation();

            } else {
                this._connMessageService.showToastMessage(`Unable to save user information!`, 'error');
            }
        }, error=>{
            this._connMessageService.showToastMessage(`Unable to save user information!`, 'error');
        })
    }

    saveFn() {
        const isvalid = this.isFormValidFn()
        if (!isvalid) return false
 
        this._spinner.show()
        this.isButtonDisable = true
        this.savePersonalInformation();
    }

    saveBusinessInformation(){

        this._signup.businessInfo.userID = this._signup.personalInfo.userID
       
        this._signup.businessInfo.email = this._signup.personalInfo.email
        this._signup.businessInfo.phone = this._signup.personalInfo.phone
        this._signup.businessInfo.address = ""
        this._signup.businessInfo.zip = "";
        this._signup.businessInfo.city = "";
        this._signup.businessInfo.state = ""; 
        this._signup.businessInfo.industry = this._signup.businessInfo.industryTypeId;
        // this._signup.businessInfo.contactEmail = this._signup.businessInfo.email;
        if (this._signup.userAccountPendingForPayment) {
            this._signup.businessInfo.businessName = this._globals.userInfo.businessSetup[0].businessDisplayName
        }
        else{
            this._signup.businessInfo.businessName = this.editform.get('companyName').value.trim()
        }
        this._signup.saveBusinessInfoV2(this._signup.businessInfo).subscribe(res => {

            if (res.success) {

                this._signup.businessInfo.businessId = res.data

                if (this._signup.accountVerified){
                    if (this._signup.selectedPlan.isTrial){
                        this._signup.currentStep = 3
                    }
                    else{
                        this._signup.currentStep = 4
                    }

                }
                else{
                    this._signup.currentStep++
                }

                this._signup.savedSteps = this._signup.currentStep;
                sessionStorage.setItem('step-2', JSON.stringify(this._signup.businessInfo))
                sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
                sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
                this.isButtonDisable = false
                this._spinner.hide()
            } else {
                this.isButtonDisable = false
                this._spinner.hide()
                this._connMessageService.showToastMessage(`Unable to save user's business information!`, 'error');
            }
        }, error => {
            this._spinner.hide()
            this._connMessageService.showToastMessage(`Unable to save user's business information!`, 'error');
        })
    }

    isFormValidFn() {
        let msg = []
        let haserror = false

        try {

        if (!this.editform.get('companyName').value) {
            msg.push('Invalid company name')
            haserror = true
        } else {
            if (this.companyNameExist) {
            msg.push('Company name already taken, please choose another name!')
            haserror = true
            }
        }

        // if (this.editform.get('email').value.length > 0 || this._globals.isValidEmail(this.editform.get('email').value)) {
        // } else {
        //     msg.push('Invalid email')
        //     haserror = true
        // }

        // if (this.editform.get('address').value.length < 1) {
        //     msg.push('Address cannot be empty!')
        //     haserror = true
        // }

        // if (this.editform.get('postalCode').value.length < 1) {
        //     msg.push('Zip Code cannot be empty!')
        //     haserror = true
        // }

        // if (this.editform.get('city').value.length < 1) {
        //     msg.push('City cannot be empty!')
        //     haserror = true
        // }

        // if (!this.selectedState) {
        //     msg.push('State must be selected!')
        //     haserror = true
        // }

        // const ph = this.editform.get('phone').value.replace(/\D/g, '')
        // if (ph.length !== 10) {
        //     msg.push('Phone number error')
        //     haserror = true
        // }
        }
        catch {
        msg.push('value in form is blank or invalid!')
        haserror = true
        }


        if (haserror) {
        if (msg.length) {
            for (let i = 0; i < msg.length; i++)
            this._connMessageService.showToastMessage(msg[i], 'error');
        }
        this._signup.ok2saveBusinessInfo = false
        return false
        } else {
        this._signup.ok2saveBusinessInfo = true
        return true
        }
    }

    backClicked() {
        this.backButtonClick.emit(true);
        this._signup.currentStep--;
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
    }

  nextClick() { }

    doesCompanyExist() {
        if (!this.editform.get('companyName').value) return true
        const name = this.editform.get('companyName').value.trim()
        this.companyNameExist = true
        try {
        this._spinner.show()
        this._signup.doesCompanyExist(name).subscribe(res => {

            if (res.success && res.data) {
            // this._connMessageService.showToastMessage('Company name already taken, please choose another name!', 'error')
            this.editform.controls['companyName'].setErrors({ 'companyexist': true })
            this._spinner.hide()
            this.companyNameExist = true
            } else if (res.success && !res.data) {
            this._spinner.hide()
            this.companyNameExist = false
            }
        })
        } catch {
        this._spinner.hide()
        this.companyNameExist = true
        }
    }

    industryTypeClick(val) {

        this._signup.businessInfo.industryTypeId = val
    }

    // zipInputEvent(value: string) {
    //     if (!value || value.length !== 5) return
    //     this.showCityWait = true
    //     this._abd.getCityAndStateInfo(value).subscribe((res: any) => {
    //     this.showCityWait = false
    //     if (res.success && res.data.city != undefined) {
    //         this.selectedState = this.usaStates.find(s => s.abbreviation == res.data.state)
    //         this._signup.businessInfo.selectedState = this.selectedState
    //         this.editform.patchValue({ city: res.data.city })
    //         this._signup.businessInfo.city = res.data.city
    //     } else {
    //         this.editform.patchValue({ city: "" })
    //         this._signup.businessInfo.city = ''
    //     }
    //     })
    // }
}
