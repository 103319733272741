import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ConnMessageService {

  constructor(private _messageService: MessageService) { }

  //-------------------------------------
  //--       GLOBAL MESSAGE-SERVICE MESSAGES
  //-------------------------------------
  showToastMessage(msg: string, type: string="error") {
    this._messageService.add({ severity: type, summary: 'Message', detail: msg })
  }
}
