import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnDestroy } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AppBaseDataService } from './_services/app-base-data.service';
import { UserService } from './_services/user.service';
import { ConnMessageService } from './_services/conn-message.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent {
    _globals = GlobalService
    breadcrumbs: MenuItem[];
    constructor(
        public appMain: AppMainComponent,
        private _router: Router,
        public _abd: AppBaseDataService,
        public _userServices:UserService,
        private _connMessageService: ConnMessageService,
    ) { }

    ngOnInit() {
        this.breadcrumbs = [
            { label: 'Categories' },
            { label: 'Sports' },
            { label: 'Football' },
            { label: 'Countries' },
            { label: 'Spain' },
            { label: 'F.C. Barcelona' },
            { label: 'Squad' },
            { label: 'Lionel Messi', url: 'https://en.wikipedia.org/wiki/Lionel_Messi' }
        ];     
    }

    settingsClicked() {
        this._router.navigate(['app/settings-main'])
    }
    isCheckIn(){
        if(this._abd?.locations.length > 0){
            this._userServices.isCheckIn = true
        }else{
            this._connMessageService.showToastMessage(
                `Location required for check-in. Please add a location to proceed.`,
                'error'
            );    
        }
    }
    logoutButtonClicked() {
        this._abd.showSpinner()
            localStorage.removeItem('user')
            sessionStorage.clear();
            
            if (window.location.href.includes('/handheld'))
            {
                try {
    
                    eval('invokeCSharpAction("logout")')
                    window.location.reload()
                }
                catch
                {
    
                }
                this._router.navigate(['handheld/signin'])
                window.location.reload()
    
            }
            else
            window.location.reload()               
    }
    // checkOutOnLogOut(): Promise<any> {
    //      return new Promise<any>((resolve, reject) => {
           
    //         this._abd.showSpinner()
    //         try {
    //                     this._abd.getTimeTrackingConfiguration().subscribe(res=>{
    //                 if(res.data.autoCheckoutOnLogout === true){
    //            this._userServices.getTimeAttendance(this._globals.userInfo.userID).subscribe((res)=>{
          
    //           let obj ={
    //             UserId:        res.data?.userId,
    //             EmployeeID:    res.data?.userId,
    //             EmployeeRole:  res.data?.employeeRole,
    //             EmployeeEmail: res.data?.employeeEmail, 
    //             EmployeeName:  res.data?.employeeName,
    //             LocationName:  res.data?.locationName,
    //             RegisterName:  res.data?.registerName,
    //             locationid:    res.data?.locationId,
    //             Type:          1,
    //             Method:        res.data?.method,
    //             reason:        1,
    //            }
    //         this._userServices.checkOutUser(obj).subscribe((res)=>{
    //          resolve ('OK');
    //         })
    //         })
    //                 }else{
    //                     resolve ('OK'); 
    //                 }
    //             })
    //         } catch (error) {
    //             resolve ('OK'); 
    //         } 
    //      });
    //    }

    changePasswordClicked() {
        this._router.navigate(['pages/change-password'])
    }
}